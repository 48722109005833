<template>
  <v-container>
    <h1>BIENVENIDO</h1>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    //
  }),
};
</script>
