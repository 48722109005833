import Vue from 'vue'
import VueRouter from 'vue-router'
import Bienvenido from '../components/Bienvenido'
import Clientes from "../components/Clientes"
import Facturacion from "../components/Facturacion"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'bienvenido',
    component: Bienvenido
  },
  {
    path: '/clientes',
    name: 'clientes',
    component: Clientes
  },
  {
    path: '/facturacion',
    name: 'facturacion',
    component: Facturacion
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
