<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <!-- SUCARSAL, TIPO DOC-->
        <v-card class="mx-0 mb-2" outlined>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" md="6" class="pa-1">
                <v-text-field
                  v-model="fecha_creacion"
                  label="Fecha"
                  type="date"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <v-autocomplete
                  v-model="sucursal"
                  :items="items_sucursales"
                  item-text="descripcion"
                  item-value="corr"
                  label="Sucursal"
                  outlined
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <v-autocomplete
                  v-model="tipo_doc_fiscal"
                  :items="items_tipo_doc_fiscales"
                  item-text="descripcion"
                  item-value="id"
                  label="Tipo Documento"
                  outlined
                  dense
                  hide-details
                >
                  <template v-slot:selection="data">
                    {{ data.item.id.trim() }} {{ data.item.descripcion }}
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.id.trim() }} {{ data.item.descripcion }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <v-autocomplete
                  v-model="tipo_pago"
                  :items="items_tipo_pago"
                  label="Tipo Pago"
                  outlined
                  dense
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- CLIENTE-->
        <v-card class="mx-0 mb-2" outlined>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="12" class="pa-1">
                <v-autocomplete
                  v-model="cliente"
                  :items="items_clientes"
                  item-text="nom_cliente"
                  item-value="cod_cliente"
                  label="Cliente"
                  outlined
                  dense
                  hide-details
                  return-object
                  @input="seleccionarCliente()"
                >
                  <template v-slot:selection="data">
                    {{ data.item.cod_cliente }} / {{ data.item.nom_cliente }}
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.cod_cliente }} / {{ data.item.nom_cliente }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="7" class="pa-1">
                <v-text-field
                  v-model="nombre_cliente"
                  label="Nombre"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5" class="pa-1">
                <v-text-field
                  v-model="registro_cliente"
                  label="Registro"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="pa-1">
                <v-text-field
                  v-model="tipo_cliente"
                  label="Tipo"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="pa-1">
                <v-text-field
                  v-model="telefono_cliente"
                  label="Teléfono"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="pa-1">
                <v-text-field
                  v-model="identifiador_cliente"
                  label="Identificador"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="pa-1">
                <v-text-field
                  v-model="num_documento_cliente"
                  label="Documento"
                  outlined
                  dense
                  hide-details
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- TABLA PRODUCTOS -->
        <v-card class="mx-0 mb-2" outlined>
          <v-card-text>
            <v-data-table
              :headers="headers_productos"
              :items="items_productos"
              dense
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row
                    v-if="$vuetify.breakpoint.mdAndUp"
                    no-gutters
                    justify="space-around"
                    align="center"
                  >
                    <v-col cols="1">
                      <v-text-field
                        v-model="cantidad"
                        label="Cantidad"
                        outlined
                        dense
                        hide-details
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-text-field
                        id="prod_ref"
                        v-model="producto"
                        label="Producto"
                        outlined
                        dense
                        hide-details
                        @keyup.enter="buscarProducto()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        outlined
                        @click="dialog_servicios = true"
                        :disabled="tipo_doc_fiscal == ''"
                      >
                        <v-icon>fa-solid fa-magnifying-glass</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="descripcion"
                        label="Descripción"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="precio"
                        label="Precio"
                        outlined
                        dense
                        hide-details
                        type="number"
                        :disabled="descripcion == ''"
                        @change="modificarPrecio()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-text-field
                        v-model="descuento_prod"
                        label="%Desc"
                        outlined
                        dense
                        hide-details
                        type="number"
                        :disabled="descripcion == ''"
                        @change="calculoDescuento()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="1">
                      <v-text-field
                        v-model="total"
                        label="Total"
                        outlined
                        dense
                        hide-details
                        type="number"
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-menu
                    bottom
                    left
                    v-if="$vuetify.breakpoint.smAndDown"
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" v-bind="attrs" v-on="on">
                        Detalles
                      </v-btn>
                    </template>
                    <v-card width="500">
                      <v-card-text>
                        <v-row justify="space-around" align="center">
                          <v-col cols="12">
                            <v-text-field
                              v-model="cantidad"
                              label="Cantidad"
                              outlined
                              dense
                              hide-details
                              type="number"
                              :disabled="tipo_doc_fiscal == ''"
                              @input="calculoCantidad"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="producto"
                              label="Producto"
                              outlined
                              dense
                              hide-details
                              @keyup.enter="buscarProducto()"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" v-if="false">
                            <v-btn
                              outlined
                              @click="dialog_servicios = true"
                              block
                            >
                              <v-icon>fa-solid fa-plus</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="descripcion"
                              label="Descripción"
                              outlined
                              dense
                              hide-details
                              :disabled="false"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="precio"
                              label="Precio"
                              outlined
                              dense
                              hide-details
                              type="number"
                              :disabled="descripcion == ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="descuento_prod"
                              label="%Desc"
                              outlined
                              dense
                              hide-details
                              type="number"
                              :disabled="descripcion == ''"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="total"
                              label="Total"
                              outlined
                              dense
                              hide-details
                              type="number"
                              disabled
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-menu>

                  <v-spacer></v-spacer>

                  <v-btn class="mx-2" @click="agregarProducto()">
                    <v-icon color="green">fa-solid fa-square-check</v-icon>
                  </v-btn>
                </v-toolbar>
              </template>

              <template v-slot:[`item.precio`]="{ item }">
                <span> $ {{ Number(item.precio).toFixed(2) }}</span>
              </template>

              <template v-slot:[`item.descuento`]="{ item }">
                <span> % {{ Number(item.descuento).toFixed(2) }}</span>
              </template>

              <template v-slot:[`item.total`]="{ item }">
                <span> $ {{ Number(item.total).toFixed(2) }}</span>
              </template>

              <template v-slot:[`item.action_edit`]="{ item }">
                <v-icon @click="editarProducto(item)">
                  fa-solid fa-pen-to-square
                </v-icon>
              </template>

              <template v-slot:[`item.action_delete`]="{ item }">
                <v-icon class="mr-2" @click="removerProducto(item)">
                  fa-solid fa-trash-can
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <!-- ULTIMA PARTE CALCULOS-->
        <v-row no-gutters justify="center" align="center" class="mx-8">
          <v-col cols="6" md="2">
            <v-text-field
              v-model="otros_cargos"
              label="Otros Cargos"
              prepend-inner-icon="fa-solid fa-dollar-sign"
              class="pa-1"
              outlined
              dense
              hide-details
            ></v-text-field>
            <v-text-field
              v-model="anticipo"
              label="Anticipo"
              prepend-inner-icon="fa-solid fa-dollar-sign"
              class="pa-1"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>

          <v-col cols="6" md="2">
            <v-text-field
              v-model="sub_total"
              label="Sub-total"
              prepend-inner-icon="fa-solid fa-dollar-sign"
              class="pa-1"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
            <v-text-field
              v-model="descuento"
              label="Descuento"
              prepend-inner-icon="fa-solid fa-dollar-sign"
              class="pa-1"
              outlined
              dense
              hide-details
              :readonly="flag_descuento"
            ></v-text-field>
          </v-col>

          <v-col cols="6" md="2">
            <v-text-field
              v-model="gravadas"
              label="Gravadas"
              prepend-inner-icon="fa-solid fa-dollar-sign"
              class="pa-1"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
            <v-text-field
              v-model="iva"
              label="IVA"
              prepend-inner-icon="fa-solid fa-dollar-sign"
              class="pa-1"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>

          <v-col cols="6" md="2">
            <v-text-field
              v-model="exentas"
              label="Exentas"
              prepend-inner-icon="fa-solid fa-dollar-sign"
              class="pa-1"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
            <v-text-field
              v-model="no_sujetas"
              label="No sujetas"
              prepend-inner-icon="fa-solid fa-dollar-sign"
              class="pa-1"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>

          <v-col cols="6" md="2">
            <v-text-field
              v-model="retencion"
              label="Retención"
              prepend-inner-icon="fa-solid fa-dollar-sign"
              class="pa-1"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
            <v-text-field
              v-model="percepcion"
              label="Percepción"
              prepend-inner-icon="fa-solid fa-dollar-sign"
              class="pa-1"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>

          <v-col cols="6" md="2">
            <v-text-field
              v-model="calculoTotalOperacion"
              label="Total operación"
              prepend-inner-icon="fa-solid fa-dollar-sign"
              class="pa-1"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
            <v-text-field
              v-model="calculoTotalPagar"
              label="Total a pagar"
              prepend-inner-icon="fa-solid fa-dollar-sign"
              class="pa-1"
              outlined
              dense
              hide-details
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions style="background-color: white">
        <v-btn color="primary" @click="crearfactura()" v-if="flag_new_edit == 1"
          >Guardar</v-btn
        >
        <v-btn color="primary" @click="dialog_print = true"
          >Generar ticket</v-btn
        >
        <v-btn
          color="primary"
          @click="actualizarfactura()"
          v-if="flag_new_edit == 2"
          :disabled="sello_recepcion != ''"
          >Actualizar</v-btn
        >
        <v-btn
          color="grey"
          v-if="false"
          :disabled="sello_recepcion == null"
          @click="enviarDTE()"
          dark
        >
          ENVIAR DTE
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="cerrarDialog()" v-if="false">Cancelar</v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog_eliminar_servicio" width="500">
      <v-card>
        <v-card-title>¿Esta seguro de eliminar este servicio?</v-card-title>
        <v-card-actions>
          <v-btn color="red" dark @click="eliminarFactura()">Eliminar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_eliminar_servicio = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog_print" width="500">
      <v-card class="px-3">
        <v-card-text class="black--text">
          <div id="printMe" style="text-align: center">
            <div style="font-size: 13px">EL CAMIONCITO</div>
            <div style="font-size: 13px">NIT: 1212 121211 212 1</div>
            <div style="font-size: 13px">NCR: 989898 8</div>
            <div style="font-size: 13px">Teléono: 2222 2222</div>
            <div style="font-size: 13px">Fecha: 23/09/2023</div>
            <div style="font-size: 13px">Ticket: 127364</div>

            <br />

            <table style="width: 100%; font-size: 9px; text-align: left">
              <tr>
                <th>Cant</th>
                <th>Desc</th>
                <th>Precio</th>
                <th>Total $</th>
              </tr>
              <tr v-for="(item, i) in items_productos" :key="i">
                <td style="width: 10%; vertical-align: top">
                  {{ item.cantidad }}
                </td>
                <td style="width: 45%; font-size: 7px; vertical-align: top">
                  {{ item.descripcion }}
                </td>
                <td style="width: 20%; vertical-align: top">
                  {{ item.precio }}
                </td>
                <td style="width: 30%; vertical-align: top">
                  {{ item.total }}
                </td>
              </tr>
            </table>
            <div>--------------------------------</div>
            <table style="width: 100%; font-size: 11px; text-align: right">
              <tr>
                <td style="width: 80%"></td>
                <td style="width: 10%">TOTAL</td>
                <td style="width: 10%">{{ total_pagar }}</td>
              </tr>
            </table>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="imprimirTicket()">Imprimir</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog_print = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      timeout="5000"
      color="blue"
      multi-line
      elevation="24"
      outlined
      top
      right
    >
      <v-icon>{{ icon_snackbar }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import printJS from "print-js";

export default {
  data: () => ({
    dialog_print: false,
    snackbar: false,
    snackbar_text: "Mensaje",
    icon_snackbar: "fa-solid fa-circle-info",
    numero_control: "DTE-00-00000000-000000000000000",
    codigo_generacion: "",
    fecha_hora: "",
    sello_recepcion: "",
    fecha_recepcion: "",
    cliente: "",
    nombre_cliente: "",
    registro_cliente: "",
    identifiador_cliente: "",
    telefono_cliente: "",
    tipo_cliente: "",
    num_documento_cliente: "",
    fecha_creacion: "",
    tipo_doc_fiscal: "",
    items_tipo_doc_fiscales: [
      {
        id: "CCF",
        descripcion: "COMPROBANTE DE CREDITO FISCAL",
        corr: "1",
        cod_MH: "03",
      },
      {
        id: "FAC",
        descripcion: "FACTURA DE CONSUMIDOR FINAL",
        corr: "2",
        cod_MH: "01",
      },
      {
        id: "FEX",
        descripcion: "FACTURA DE EXPORTACION",
        corr: "3",
        cod_MH: "11",
      },
      {
        id: "NCR",
        descripcion: "NOTA DE CREDITO",
        corr: "4",
        cod_MH: "05",
      },
      {
        id: "TCK",
        descripcion: "TICKET DE CAJA",
        corr: "5",
        cod_MH: "",
      },
    ],
    tipo_pago: "",
    items_tipo_pago: ["CONTADO", "CREDITO"],
    //SUCURSAL
    sucursal: 1,
    items_sucursales: [{ corr: 1, descripcion: "CASA MATRIZ", cod_MH: "02" }],
    //IDENTIFICADORES
    items_identificador: [],
    //TABLA PRODUCTO
    headers_productos: [
      { text: "Cantidad", value: "cantidad" },
      { text: "Producto", value: "cod_servicio" },
      { text: "Descripción", value: "descripcion" },
      { text: "Precio", value: "precio" },
      { text: "Descuento", value: "descuento" },
      { text: "Total", value: "total" },
      { text: "Editar", value: "action_edit" },
      { text: "Eliminar", value: "action_delete" },
    ],
    items_productos: [],
    items_productos_test: [
      {
        cantidad: 1,
        cod_servicio: "074470892823",
        descripcion: "PRODUCTO 1",
        descuento: 1,
        exento: false,
        id: 1,
        id_detalle_factura: -1,
        no_sujeto: false,
        precio: 25,
        total: "24.75",
      },
      {
        cantidad: 1,
        cod_servicio: "074470892823",
        descripcion: "PRODUCTO 1",
        descuento: 1,
        exento: false,
        id: 1,
        id_detalle_factura: -1,
        no_sujeto: false,
        precio: 25,
        total: "24.75",
      },
    ],
    cantidad: 1,
    producto: "",
    descripcion: "",
    precio: 0,
    descuento_prod: 0,
    monto_descuento: 0,
    total: 0,
    exento_prod: false,
    no_sujeto_prod: false,
    id_detalle_factura: 0,
    cont_prod: 0,
    id: 0,
    item_servicio_eliminar: "",
    //CLIENTES
    id_cliente: 0,
    headers_clientes: [
      { text: "Cliente", value: "codigo", sorteable: false },
      { text: "Nombre", value: "nombre", sorteable: false },
      { text: "Registro", value: "registro", sorteable: false },
      {
        text: "Identificador",
        value: "identificador_nombre",
        sorteable: false,
      },
      { text: "Teléfono", value: "telefono", sorteable: false },
      { text: "Tipo", value: "tipo_persona", sorteable: false },
    ],
    items_clientes: [],
    cliente_exento: false,
    cliente_retencion: false,
    cliente_percepcion: false,
    cliente_tamaño: "",
    cliente_extranjero: false,
    //CALCULO DE VALORES
    otros_cargos: 0.0,
    anticipo: 0.0,
    sub_total: 0.0,
    descuento: 0.0,
    gravadas: 0.0,
    iva: 0.0,
    exentas: 0.0,
    no_sujetas: 0.0,
    retencion: 0.0,
    percepcion: 0.0,
    total_operacion: 0.0,
    total_pagar: 0.0,
    flag_descuento: false,
    flag_new_edit: 1,
    ////////////////////////
    dialog_eliminar_servicio: false,
  }),
  computed: {
    calculoCantidad() {
      this.total = (Number(this.cantidad) * Number(this.precio)).toFixed(2);
    },
    calculoTotalOperacion() {
      this.total_operacion = (
        Number(this.gravadas) +
        Number(this.iva) -
        Number(this.retencion) +
        Number(this.percepcion) +
        Number(this.exentas) +
        Number(this.no_sujetas)
      ).toFixed(2);
      return this.total_operacion;
    },
    calculoTotalPagar() {
      this.total_pagar = (
        Number(this.gravadas) +
        Number(this.iva) -
        Number(this.retencion) +
        Number(this.percepcion) +
        Number(this.exentas) +
        Number(this.no_sujetas) +
        Number(this.otros_cargos)
      ).toFixed(2);
      return this.total_pagar;
    },
  },
  created() {
    this.listarClientes();
  },
  methods: {
    generarTicket() {
      this.dialog_print = true;
    },
    imprimirTicket() {
      printJS({
        printable: "printMe",
        type: "html",
        scanStyles: false,
      });
    },
    listarClientes() {
      axios
        .get("api/Clientes/Listar")
        .then((response) => {
          this.items_clientes = response.data;
          //console.log(response.data);
        })
        .catch(() => {
          this.snackbar_text = "No se pudo obtener a los clientes";
          this.snackbar = true;
        });
    },
    seleccionarCliente() {
      //console.log(this.cliente);
      this.nombre_cliente = this.cliente.nom_cliente;
      this.registro_cliente = this.cliente.registro;
      this.telefono_cliente = this.cliente.telefono;
      this.tipo_cliente = this.cliente.tipo_persona;
      this.identifiador_cliente = this.cliente.desc_identificador;
      this.num_documento_cliente = this.cliente.documento_identificador;

      if (this.cliente.tipo_persona == 1) {
        this.tipo_cliente = "NATURAL";
      } else if (this.cliente.tipo_persona == 2) {
        this.tipo_cliente = "JURIDICA";
      } else {
        this.tipo_cliente = "";
      }
    },
    buscarProducto() {
      //console.log(this.producto);
      var palabras = [
        { descripcion: "PRODUCTO 1", precio: 25.0, desc: 1 },
        { descripcion: "PRODUCTO 2", precio: 35.0, desc: 2 },
        { descripcion: "PRODUCTO 3", precio: 45.0, desc: 3 },
        { descripcion: "PRODUCTO 4", precio: 55.0, desc: 4 },
        { descripcion: "PRODUCTO 5", precio: 65.0, desc: 5 },
      ];

      this.descripcion = palabras[Math.floor(Math.random())].descripcion;
      this.precio = palabras[Math.floor(Math.random())].precio;
      this.descuento_prod = palabras[Math.floor(Math.random())].desc;
      this.total = (Number(this.cantidad) * Number(this.precio)).toFixed(2);

      this.monto_descuento = 0;
      this.monto_descuento =
        Number(this.cantidad) *
        Number(this.precio) *
        (Number(this.descuento_prod) / 100);

      this.total = (Number(this.total) - Number(this.monto_descuento)).toFixed(
        2
      );

      this.agregarProducto();
    },
    agregarProducto() {
      if (!this.cliente || !this.tipo_doc_fiscal) {
        this.snackbar_text =
          "Debe de seleccionar el tipo de documento fiscal y el cliente";
        this.snackbar = true;
        return;
      }
      if (this.tipo_doc_fiscal.cod_doc_fiscal == "FEX") {
        if (this.cliente_extranjero == false) {
          this.snackbar_text = "El cliente no es extranjero";
          this.snackbar = true;
          return;
        }
      }

      if (this.id_detalle_factura > 0 || this.id_detalle_factura === -1) {
        this.editarDetalleServicio();
        return;
      }

      this.cont_prod++;

      this.sumarDescuentos();

      this.items_productos.push({
        id: this.cont_prod,
        id_detalle_factura: -1,
        cantidad: this.cantidad,
        cod_servicio: this.producto,
        descripcion: this.descripcion,
        precio: this.precio,
        descuento: this.descuento_prod,
        total: this.total,
        exento: this.exento_prod,
        no_sujeto: this.no_sujeto_prod,
      });

      if (this.cliente_exento) {
        this.calculoExento(this.total);
        this.limpiarCamposProductos();
        return;
      }

      if (this.exento_prod) {
        this.calculoExento(this.total);
        this.limpiarCamposProductos();
        return;
      }
      if (this.no_sujeto_prod) {
        this.calculoNoSujeto();
        this.limpiarCamposProductos();
        return;
      }

      if (this.cliente_retencion) {
        if (this.total >= 100) {
          this.retencion = (Number(this.total) * Number(0.01)).toFixed(2);
        }
      }
      if (this.cliente_percepcion) {
        if (this.total >= 100) {
          this.percepcion = (Number(this.total) * Number(0.01)).toFixed(2);
        }
      }

      this.sumarSubTotales(this.total);
      this.calculoGravadas(this.sub_total, this.descuento);

      if (
        this.tipo_doc_fiscal.cod_doc_fiscal == "CCF" ||
        this.tipo_doc_fiscal.cod_doc_fiscal == "NCR" ||
        this.tipo_doc_fiscal.cod_doc_fiscal == "NDB"
      ) {
        this.calculoIva(this.gravadas);
      }

      //console.log(this.items_productos);

      this.limpiarCamposProductos();
    },
    removerProducto(item) {
      this.item_servicio_eliminar = item;
      this.dialog_eliminar_servicio = true;
    },
    eliminarFactura() {
      if (this.item_servicio_eliminar.id_detalle_factura > 0) {
        axios
          .delete(
            "api/DetallesFacturas/Eliminar/" +
              this.item_servicio_eliminar.id_detalle_factura
          )
          .then(() => {
            var index = this.items_productos.findIndex(
              (item) =>
                item.id_detalle_factura ==
                this.item_servicio_eliminar.id_detalle_factura
            );
            if (index != -1) {
              this.items_productos.splice(index, 1);
            }

            this.limpiarCalculosCabecera();

            for (var i = 0; i < this.items_productos.length; i++) {
              //CALCULOS DE DESCUENTO
              this.sumarDescuentos();

              //CALCULOS DE EXENTO Y NO SUJETO
              if (this.cliente_exento) {
                this.calculoExento(this.items_productos[i].total);
                this.limpiarCamposProductos();
                continue;
              }

              if (this.items_productos[i].exento) {
                this.calculoExento(this.items_productos[i].total);
                this.limpiarCamposProductos();
                continue;
              }
              if (this.items_productos[i].no_sujeto) {
                this.calculoNoSujeto();
                this.limpiarCamposProductos();
                continue;
              }

              if (this.cliente_retencion) {
                if (this.total >= 100) {
                  this.retencion = (Number(this.total) * Number(0.01)).toFixed(
                    2
                  );
                }
              }
              if (this.cliente_percepcion) {
                if (this.total >= 100) {
                  this.percepcion = (Number(this.total) * Number(0.01)).toFixed(
                    2
                  );
                }
              }

              this.sumarSubTotales(this.items_productos[i].total);
              this.calculoGravadas(this.sub_total, this.descuento);

              if (
                this.tipo_doc_fiscal.cod_doc_fiscal == "CCF" ||
                this.tipo_doc_fiscal.cod_doc_fiscal == "NCR" ||
                this.tipo_doc_fiscal.cod_doc_fiscal == "NDB"
              ) {
                this.calculoIva(this.gravadas);
              }
            }
            this.dialog_eliminar_servicio = false;
            this.snackbar_text = "Servicio eliminado";
            this.snackbar = true;
          })
          .catch(() => {
            this.snackbar_text = "Servicio no se pudo eliminar";
            this.snackbar = true;
          });
      } else {
        var index = this.items_productos.findIndex(
          (item) =>
            item.id_detalle_factura ==
            this.item_servicio_eliminar.id_detalle_factura
        );
        if (index != -1) {
          this.items_productos.splice(index, 1);
        }

        this.limpiarCalculosCabecera();

        for (var i = 0; i < this.items_productos.length; i++) {
          //CALCULOS DE DESCUENTO
          this.sumarDescuentos();

          //CALCULOS DE EXENTO Y NO SUJETO
          if (this.cliente_exento) {
            this.calculoExento(this.items_productos[i].total);
            this.limpiarCamposProductos();
            continue;
          }

          if (this.items_productos[i].exento) {
            this.calculoExento(this.items_productos[i].total);
            this.limpiarCamposProductos();
            continue;
          }
          if (this.items_productos[i].no_sujeto) {
            this.calculoNoSujeto();
            this.limpiarCamposProductos();
            continue;
          }

          if (this.cliente_retencion) {
            if (this.total >= 100) {
              this.retencion = (Number(this.total) * Number(0.01)).toFixed(2);
            }
          }
          if (this.cliente_percepcion) {
            if (this.total >= 100) {
              this.percepcion = (Number(this.total) * Number(0.01)).toFixed(2);
            }
          }

          this.sumarSubTotales(this.items_productos[i].total);
          this.calculoGravadas(this.sub_total, this.descuento);

          if (
            this.tipo_doc_fiscal.cod_doc_fiscal == "CCF" ||
            this.tipo_doc_fiscal.cod_doc_fiscal == "NCR" ||
            this.tipo_doc_fiscal.cod_doc_fiscal == "NDB"
          ) {
            this.calculoIva(this.gravadas);
          }
        }
        this.dialog_eliminar_servicio = false;
        this.snackbar_text = "Servicio eliminado";
        this.snackbar = true;
      }
    },
    editarDetalleServicio() {
      if (this.id_detalle_factura == -1) {
        var index = this.items_productos.findIndex(
          (item) => item.id == this.id
        );
      } else {
        var index = this.items_productos.findIndex(
          (item) => item.id_detalle_factura == this.id_detalle_factura
        );
      }

      this.items_productos[index].cantidad = this.cantidad;
      this.items_productos[index].cod_servicio = this.producto;
      this.items_productos[index].descripcion = this.descripcion;
      this.items_productos[index].precio = this.precio;
      this.items_productos[index].descuento = this.descuento_prod;
      this.items_productos[index].total = this.total;
      this.items_productos[index].exento = this.exento_prod;
      this.items_productos[index].no_sujeto = this.no_sujeto_prod;

      this.limpiarCalculosCabecera();

      for (var i = 0; i < this.items_productos.length; i++) {
        //CALCULOS DE DESCUENTO
        this.sumarDescuentos();

        //CALCULOS DE EXENTO Y NO SUJETO
        if (this.cliente_exento) {
          this.calculoExento(this.items_productos[i].total);
          this.limpiarCamposProductos();
          continue;
        }

        if (this.items_productos[i].exento) {
          this.calculoExento(this.items_productos[i].total);
          this.limpiarCamposProductos();
          continue;
        }
        if (this.items_productos[i].no_sujeto) {
          this.calculoNoSujeto();
          this.limpiarCamposProductos();
          continue;
        }

        if (this.cliente_retencion) {
          if (this.total >= 100) {
            this.retencion = (Number(this.total) * Number(0.01)).toFixed(2);
          }
        }
        if (this.cliente_percepcion) {
          if (this.total >= 100) {
            this.percepcion = (Number(this.total) * Number(0.01)).toFixed(2);
          }
        }

        this.sumarSubTotales(this.items_productos[i].total);
        this.calculoGravadas(this.sub_total, this.descuento);

        if (
          this.tipo_doc_fiscal.cod_doc_fiscal == "CCF" ||
          this.tipo_doc_fiscal.cod_doc_fiscal == "NCR" ||
          this.tipo_doc_fiscal.cod_doc_fiscal == "NDB"
        ) {
          this.calculoIva(this.gravadas);
        }

        this.limpiarCamposProductos();
      }
    },
    editarProducto(item) {
      //console.log(item);
      this.id = item.id;
      this.id_detalle_factura = item.id_detalle_factura;
      this.cantidad = item.cantidad;
      this.producto = item.cod_servicio;
      this.descripcion = item.descripcion;
      this.precio = item.precio;
      this.descuento_prod = item.descuento;
      this.total = item.total;
      this.exento_prod = item.exento;
      this.no_sujeto_prod = item.no_sujeto;
    },
    modificarPrecio() {
      this.precio = Number(this.precio).toFixed(2);
      this.total = Number(this.precio).toFixed(2);
    },
    calculoDescuento() {
      this.monto_descuento = 0;
      this.monto_descuento =
        Number(this.cantidad) *
        Number(this.precio) *
        (Number(this.descuento_prod) / 100);
      this.total = (Number(this.total) - Number(this.monto_descuento)).toFixed(
        2
      );
    },
    limpiarCalculosCabecera() {
      this.otros_cargos = 0;
      this.anticipo = 0;
      this.sub_total = 0;
      this.descuento = 0;
      this.gravadas = 0;
      this.iva = 0;
      this.exentas = 0;
      this.no_sujetas = 0;
      this.retencion = 0;
      this.percepcion = 0;
      this.total_operacion = 0;
      this.total_pagar = 0;
    },
    limpiarCamposProductos() {
      this.cantidad = 1;
      this.producto = "";
      this.descripcion = "";
      this.precio = 0;
      this.descuento_prod = 0;
      this.total = 0;

      document.getElementById("prod_ref").focus();
    },
    sumarSubTotales(v_total) {
      this.sub_total = parseFloat(
        Number(this.sub_total) + Number(v_total)
      ).toFixed(2);
    },
    sumarDescuentos() {
      if (this.descuento_prod > 0) {
        this.flag_descuento = true;
      }
      this.descuento = parseFloat(
        Number(this.descuento) + Number(this.monto_descuento)
      ).toFixed(2);
    },
    calculoGravadas(v_sub_total, v_descuento) {
      this.gravadas = parseFloat(
        Number(v_sub_total) - Number(v_descuento)
      ).toFixed(2);
    },
    calculoIva(v_gravadas) {
      this.iva = parseFloat(Number(v_gravadas) * Number(0.13)).toFixed(2);
    },
    calculoExento(v_total) {
      this.exentas = (Number(this.exentas) + Number(v_total)).toFixed(2);
    },
    calculoNoSujeto() {
      this.no_sujetas = (Number(this.no_sujetas) + Number(this.total)).toFixed(
        2
      );
    },
  },
};
</script>