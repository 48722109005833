<template>
  <v-container fluid>
    <v-data-table :headers="headers" :items="items" :search="search">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Clientes</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Buscar"
            outlined
            dense
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="nuevoCliente()"> Nuevo </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.tipo_persona`]="{ item }">
        <div v-if="item.tipo_persona == 1"><span>NATURAL</span></div>
        <div v-if="item.tipo_persona == 2"><span>JURIDICA</span></div>
      </template>

      <template v-slot:[`item.action_edit`]="{ item }">
        <v-icon class="mr-2" @click="editarCliente(item)">
          fa-solid fa-pen-to-square
        </v-icon>
      </template>
    </v-data-table>

    <!-- VENTANA PARA PODER VER, CREAR O ACTUALIZAR UN CLIENTE -->
    <v-dialog v-model="dialog" width="1000" persistent scrollable>
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-checkbox
            v-model="cliente_activo"
            label="CLIENTE ACTIVO"
            dense
            hide-details
          ></v-checkbox>
        </v-card-title>
        <v-card-text class="px-0 pb-0">
          <!-- FECHA, TIPO DOC, CODIGO, NOMBRE ... -->
          <v-row no-gutters class="my-1">
            <v-col cols="12">
              <v-card
                class="mx-3"
                elevation="0"
                style="border: 1px solid black"
              >
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12" md="6" class="pa-1">
                      <v-text-field
                        v-model="fecha_ingreso"
                        label="Fecha ingreso"
                        outlined
                        dense
                        hide-details
                        type="date"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-1">
                      <v-autocomplete
                        v-model="id_tipo_doc_fiscal"
                        :items="items_tipo_doc_fiscal"
                        item-text="descripcion"
                        item-value="id"
                        label="Tipo documento fiscal"
                        outlined
                        dense
                        hide-details
                      >
                        <template v-slot:selection="data">
                          {{ data.item.id.trim() }} {{ data.item.descripcion }}
                        </template>
                        <template v-slot:item="data">
                          {{ data.item.id.trim() }} {{ data.item.descripcion }}
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                        v-model="codigo"
                        label="Código"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pa-1">
                      <v-text-field
                        v-model="nombre"
                        label="Nombre"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pa-1">
                      <v-text-field
                        v-model="razon_social"
                        label="Razón social"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-1">
                      <v-autocomplete
                        v-model="id_departamento"
                        :items="items_departamento"
                        item-text="nom_dept"
                        item-value="cod_dept"
                        label="Departamento"
                        outlined
                        dense
                        hide-details
                        @input="listarMunicipios(id_departamento)"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" class="pa-1">
                      <v-autocomplete
                        v-model="id_municipio"
                        :items="items_municipio"
                        item-text="nom_muni"
                        item-value="cod_muni"
                        label="Municipio"
                        outlined
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" class="pa-1">
                      <v-text-field
                        v-model="direccion"
                        label="Dirección"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                      <v-autocomplete
                        v-model="id_actividad_economica"
                        :items="items_actividad_economica"
                        item-value="id"
                        item-text="descripcion"
                        label="Actividad Económica"
                        outlined
                        dense
                        hide-details
                      >
                        <template v-slot:selection="data">
                          {{ data.item.id.trim() }} {{ data.item.descripcion }}
                        </template>
                        <template v-slot:item="data">
                          {{ data.item.id.trim() }} {{ data.item.descripcion }}
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4" class="pa-1">
                      <v-autocomplete
                        v-model="tamaño"
                        :items="items_tamaño"
                        label="Tamaño"
                        outlined
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                        v-model="registro"
                        label="Registro"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                        v-model="email_1"
                        label="Email"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                        v-model="email_2"
                        label="Email 2"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="8" class="pa-1">
                      <v-text-field
                        v-model="contacto"
                        label="Contacto"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                        v-model="telefono"
                        label="Teléfono"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="pa-1">
                      <v-autocomplete
                        v-model="tipo_persona"
                        :items="items_tipo_persona"
                        label="Tipo"
                        outlined
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4" class="pa-1">
                      <v-autocomplete
                        v-model="id_identificador"
                        :items="items_identificador"
                        item-text="descripcion"
                        item-value="id"
                        label="Identificador"
                        outlined
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                        v-model="documento"
                        label="Documento"
                        outlined
                        dense
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- CHECKBOX ... -->
          <v-row no-gutters class="my-1">
            <v-col cols="12">
              <v-card
                class="mx-3"
                elevation="0"
                outlined
                style="border: 1px solid black"
              >
                <v-card-text>
                  <v-row justify="center">
                    <v-col cols="12" md="auto">
                      <v-checkbox
                        v-model="con_retencion"
                        label="CON RETENCION"
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="auto">
                      <v-checkbox
                        v-model="con_percepcion"
                        label="CON PERCEPCION"
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="auto">
                      <v-checkbox
                        v-model="exento"
                        label="EXENTO"
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="6" align-self="center" v-if="false">
              <v-card class="mx-3" elevation="3">
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-checkbox
                        v-model="con_credito"
                        label="CON CREDITO"
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                      <v-checkbox
                        v-model="exede_credito"
                        label="EXEDE CREDITO"
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="6" class="pa-1">
                      <v-text-field
                        v-if="false"
                        v-model="limite_credito"
                        label="Limite de credito"
                        outlined
                        dense
                        hide-details
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="pa-1">
                      <v-text-field
                        v-if="false"
                        v-model="maximo_dias_limite"
                        label="Maximo días limite"
                        outlined
                        dense
                        hide-details
                        type="number"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- EXTRANJERO, TIPO EXPORT, ZONA, PAIS -->
          <v-row no-gutters class="my-1" v-if="false">
            <v-col cols="12">
              <v-card
                class="mx-3"
                elevation="0"
                outlined
                style="border: 1px solid black"
              >
                <v-card-text>
                  <v-row no-gutters justify="space-between">
                    <v-col cols="auto" md="3">
                      <v-checkbox
                        v-model="extranjero"
                        label="EXTRANJERO"
                        dense
                        hide-details
                        @click="seleccionExtranjero()"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="5" class="pa-1">
                      <v-autocomplete
                        v-model="id_tipo_exportacion"
                        :items="items_tipo_export"
                        item-text="nombre"
                        item-value="id_tipo_exportacion"
                        label="Tipo exportacion"
                        outlined
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="auto" md="3">
                      <v-checkbox
                        v-model="es_zona_franca_dpa"
                        label="ES ZONA FRANCA O D.P.A."
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="4" class="pa-1 mx-auto">
                      <v-autocomplete
                        v-model="id_pais"
                        :items="items_paises"
                        item-text="nombre"
                        item-value="id_pais"
                        label="País"
                        outlined
                        dense
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="crearCliente()"
            v-if="flag_nuevo_edit == 1"
            >Guardar</v-btn
          >
          <v-btn
            color="primary"
            @click="actualizarCliente()"
            v-if="flag_nuevo_edit == 2"
            >Actualizar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="cerrarDialogCliente()">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      timeout="8000"
      color="blue"
      multi-line
      elevation="24"
      outlined
      top
      right
    >
      <v-icon>{{ icon_snackbar }}</v-icon>
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data: () => ({
    search: "",
    snackbar: false,
    snackbar_text: "Mensaje",
    icon_snackbar: "fa-solid fa-circle-info",
    headers: [
      { text: "Cliente", value: "cod_cliente" },
      { text: "Nombre", value: "nom_cliente" },
      { text: "Registro", value: "registro" },
      { text: "Identificador", value: "desc_identificador" },
      { text: "Teléfono", value: "telefono" },
      { text: "Tipo", value: "tipo_persona" },
      { text: "Editar", value: "action_edit", sortable: false },
    ],
    items: [],
    id_empresa: 0,
    //CLIENTE
    id_cliente: 0,
    fecha_ingreso: "",
    id_tipo_doc_fiscal: "",
    items_tipo_doc_fiscal: [
      {
        id: "CCF",
        descripcion: "COMPROBANTE DE CREDITO FISCAL",
        corr: "1",
        cod_MH: "03",
      },
      {
        id: "FAC",
        descripcion: "FACTURA DE CONSUMIDOR FINAL",
        corr: "2",
        cod_MH: "01",
      },
      {
        id: "FEX",
        descripcion: "FACTURA DE EXPORTACION",
        corr: "3",
        cod_MH: "11",
      },
      {
        id: "NCR",
        descripcion: "NOTA DE CREDITO",
        corr: "4",
        cod_MH: "05",
      },
      {
        id: "TCK",
        descripcion: "TICKET DE CAJA",
        corr: "5",
        cod_MH: "",
      },
    ],
    codigo: "",
    nombre: "",
    razon_social: "",
    id_departamento: null,
    items_departamento: [],
    id_municipio: null,
    items_municipio: [],
    direccion: "",
    id_actividad_economica: "",
    items_actividad_economica: [],
    tamaño: "",
    items_tamaño: ["PEQUEÑA", "MEDIANO", "GRANDE", "OTROS"],
    registro: "",
    email_1: "",
    email_2: "",
    contacto: "",
    telefono: "",
    tipo_persona: "",
    items_tipo_persona: ["NATURAL", "JURIDICA"],
    id_identificador: "",
    items_identificador: [],
    documento: "",
    con_retencion: false,
    cliente_activo: true,
    exento: false,
    con_percepcion: false,
    con_credito: false,
    exede_credito: false,
    limite_credito: "",
    maximo_dias_limite: "",
    extranjero: false,
    id_tipo_exportacion: "",
    items_tipo_export: [],
    es_zona_franca_dpa: false,
    id_pais: "",
    items_paises: [],
    dialog: false,
    flag_nuevo_edit: 1,
  }),
  created() {
    this.listarClientes();
    this.listarDepartamentos();
    this.listarActEconomicas();
    this.listarIdentificadores();
  },
  methods: {
    listarClientes() {
      axios
        .get("api/Clientes/Listar")
        .then((response) => {
          this.items = response.data;
        })
        .catch(() => {
          this.snackbar_text = "No se pudo obtener a los clientes";
          this.snackbar = true;
        });
    },
    nuevoCliente() {
      this.flag_nuevo_edit = 1;
      this.dialog = true;

      let date = new Date();
      this.fecha_ingreso =
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        date.getDate();
    },
    listarDepartamentos() {
      axios
        .get("api/Departamentos/Listar")
        .then((response) => {
          this.items_departamento = response.data;
        })
        .catch(() => {
          this.snackbar_text = "No cargaron los departamentos";
          this.snackbar = true;
        });
    },
    listarMunicipios(id) {
      axios
        .get("api/Municipios/Listar/" + id)
        .then((response) => {
          this.items_municipio = response.data;
        })
        .catch(() => {
          this.snackbar_text = "No cargaron los municipios";
          this.snackbar = true;
        });
    },
    listarActEconomicas() {
      axios
        .get("api/ActEconomicas/Listar")
        .then((response) => {
          this.items_actividad_economica = response.data;
        })
        .catch(() => {
          this.snackbar_text = "No cargaron las actividades economicas";
          this.snackbar = true;
        });
    },
    listarIdentificadores() {
      axios.get("api/identificadores/Listar").then((response) => {
        this.items_identificador = response.data;
      });
    },
    crearCliente() {
      let tip_persona = "";
      if(this.tipo_persona == "NATURAL") {
        tip_persona = 1;
      } else if(this.tipo_persona == "JURIDICA") {
        tip_persona = 2;
      } else {
        tip_persona = "";
      }

      axios
        .post("api/Clientes/Crear", {
          fecha_ingreso: this.fecha_ingreso,
          cod_actividad_economica: this.id_actividad_economica,
          cod_cliente: this.codigo,
          cod_dept: this.id_departamento,
          cod_muni: this.id_municipio,
          cod_identificador: this.id_identificador,
          cod_tipo_documento: this.id_tipo_doc_fiscal,
          contacto: this.contacto,
          con_percepcion: this.con_percepcion ? 1 : 0,
          con_retencion: this.con_percepcion ? 1 : 0,
          dir_cliente: this.direccion,
          documento_identificador: this.documento,
          email: this.email_1,
          email2: this.email_2,
          exento: this.exento ? 1 : 0,
          nom_cliente: this.nombre,
          razon_social: this.razon_social,
          registro: this.registro,
          tama: this.tamaño,
          telefono: this.telefono,
          tipo_persona: tip_persona,
        })
        .then(() => {
          this.cerrarDialogCliente();
          this.listarClientes();

          this.snackbar_text = "Registro creado con exito";
          this.snackbar = true;
        })
        .catch(() => {
          this.snackbar_text = "No se pudo crear el registro";
          this.snackbar = true;
        });
    },
    actualizarCliente() {
      let tip_persona = "";
      if(this.tipo_persona == "NATURAL") {
        tip_persona = 1;
      } else if(this.tipo_persona == "JURIDICA") {
        tip_persona = 2;
      } else {
        tip_persona = "";
      }

      axios
        .put("api/Clientes/Actualizar", {
          fecha_ingreso: this.fecha_ingreso,
          cod_actividad_economica: this.id_actividad_economica,
          cod_cliente: this.codigo,
          cod_dept: this.id_departamento,
          cod_muni: this.id_municipio,
          cod_identificador: this.id_identificador,
          cod_tipo_documento: this.id_tipo_doc_fiscal,
          contacto: this.contacto,
          con_percepcion: this.con_percepcion ? 1 : 0,
          con_retencion: this.con_percepcion ? 1 : 0,
          corr: this.id_cliente,
          dir_cliente: this.direccion,
          documento_identificador: this.documento,
          email: this.email_1,
          email2: this.email_2,
          exento: this.exento ? 1 : 0,
          nom_cliente: this.nombre,
          razon_social: this.razon_social,
          registro: this.registro,
          tama: this.tamaño,
          telefono: this.telefono,
          tipo_persona: tip_persona,
        })
        .then(() => {
          this.cerrarDialogCliente();
          this.listarClientes();

          this.snackbar_text = "Registro actualizado con exito";
          this.snackbar = true;
        })
        .catch(() => {
          this.snackbar_text = "No se pudo actualizar el registro";
          this.snackbar = true;
        });
    },
    editarCliente(item) {
      //console.log(item);
      this.id_cliente = item.corr;
      this.fecha_ingreso = this.formatoFecha(item.fecha_ingreso);
      this.codigo = item.cod_cliente;
      this.nombre = item.nom_cliente;
      this.razon_social = item.razon_social;
      this.direccion = item.dir_cliente;
      this.registro = item.registro;
      this.email_1 = item.email;
      this.email_2 = item.email2;
      this.contacto = item.contacto;
      this.telefono = item.telefono;
      this.documento = item.documento_identificador;
      this.con_retencion = item.con_retencion;
      this.con_percepcion = item.con_percepcion;
      this.exento = item.exento;
      this.id_departamento = item.cod_dept;
      this.id_municipio = item.cod_muni;
      this.tamaño = item.tama;
      this.id_actividad_economica = item.cod_actividad_economica;
      this.id_identificador = item.cod_identificador;
      this.id_tipo_doc_fiscal = item.cod_tipo_documento;

      if (item.tipo_persona == 1) {
        this.tipo_persona = "NATURAL";
      } else if (item.tipo_persona == 2) {
        this.tipo_persona = "JURIDICA";
      } else {
        this.tipo_persona = "";
      }

      this.listarMunicipios(item.cod_dept);

      this.flag_nuevo_edit = 2;
      this.dialog = true;
    },
    cerrarDialogCliente() {
      this.dialog = false;

      this.fecha_ingreso = "";
      this.codigo = "";
      this.nombre = "";
      this.razon_social = "";
      this.direccion = "";
      this.registro = "";
      this.email_1 = "";
      this.email_2 = "";
      this.contacto = "";
      this.telefono = "";
      this.documento = "";
      this.con_retencion = "";
      this.con_percepcion = "";
      this.exento = "";
      this.id_tipo_doc_fiscal = "";
      this.id_departamento = "";
      this.id_municipio = "";
      this.id_actividad_economica = "";
      this.tamaño = "";
      this.tipo_persona = "";
      this.id_identificador = "";
    },
    formatoFecha(fecha) {
      var split_fecha = fecha.split("/");
      var fecha_formato =
        split_fecha[2] +
        "-" +
        split_fecha[1] +
        "-" +
        split_fecha[0] +
        " " +
        "00:00:00";

      var date = new Date(fecha_formato);
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>