<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      :clipped-left="$vuetify.breakpoint.mdAndUp"
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="require('@/assets/icon.png')"
          transition="scale-transition"
          width="40"
        />

        <v-toolbar-title>El Camioncito</v-toolbar-title>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      app
      v-model="drawer"
      :clipped="$vuetify.breakpoint.mdAndUp"
    >
      <!-- INICIO-->
      <v-list dense nav rounded>
        <v-list-item
          v-for="item in item_drawer_inicio"
          :key="item.title"
          :to="item.href"
          active-class="primary--text"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <!-- CATALOGOS -->
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="text-center body-2"
              >CATALOGOS</v-list-item-title
            >
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="item in items_drawer_catalogos"
          :key="item.title"
          :to="item.href"
          dense
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <!-- ITEMS SEPARADOS-->
      <v-list dense nav rounded>
        <v-list-item
          v-for="item in item_drawer_individuales"
          :key="item.title"
          :to="item.href"
          active-class="primary--text"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: true,
    item_drawer_inicio: [
      { title: "Bienvenido", icon: "fa-solid fa-house", href: "/" },
    ],
    items_drawer_catalogos: [
      { title: "Clientes", icon: "fa-solid fa-users", href: "/clientes" },
    ],
    item_drawer_individuales: [
      { title: "Facturación", icon: "fa-solid fa-receipt", href: "/facturacion" }
    ]
  }),
};
</script>
